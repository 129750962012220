.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bubble {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle at top left, #8ec5fc, #e0c3fc);
  cursor: pointer;
}

body{
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #92899e;
  background-image: url("./back.png");
  background-position: center;

}

.page{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social{
  display: flex;
  justify-content: center;
}

.info{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link{
  width: 160px;
  height:55px;
  background: radial-gradient(circle at top left, #8ec5fc, #e0c3fc);
  border-radius: 0.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 21px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  transition: 0.35s;
}

.link:hover{
  transform: scale(1.08);
  transition: 0.35s;
  color: rgba(255, 255, 255, 0.9);
}

.tokenCa{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: 20px;
  border-radius: 40px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.9);
}